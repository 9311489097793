import { format } from "date-fns";
import { Feature } from "ol";
import { FeatureLike } from "ol/Feature";
import Overlay from "ol/Overlay";
import { Geometry } from "ol/geom";
import OLVectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { StyleLike } from "ol/style/Style";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { ELayer } from "@models/Grafica";
import { EStato } from "@models/Tomba";

import { featureLayer, featureProperties } from "@openLayersMap/Helpers/EditMappingHelpers";
import { getDefunti } from "@openLayersMap/Helpers/MappingHelpers";

import { useMap } from "../Map/MapContext";

interface VectorLayerProps {
    source: VectorSource;
    style?: StyleLike;
}

const getContrastingColor = (bgColor: string | undefined): string => {
    if (!bgColor) return "#000000";

    let r;
    let g;
    let b;
    if (bgColor.startsWith("#")) {
        const color = bgColor.substring(1, 7);
        r = parseInt(color.substring(0, 2), 16);
        g = parseInt(color.substring(2, 4), 16);
        b = parseInt(color.substring(4, 6), 16);
    } else if (bgColor.startsWith("rgba")) {
        const rgba = bgColor.match(/rgba?\((\d+), (\d+), (\d+),? ([\d.]+)?\)/);
        if (rgba) {
            r = parseInt(rgba[1], 10);
            g = parseInt(rgba[2], 10);
            b = parseInt(rgba[3], 10);
        } else {
            return "#000000";
        }
    } else {
        return "#000000";
    }

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

const createMagnifyElement = (feature: FeatureLike, layer: ELayer) => {
    const properties = featureProperties(feature as Feature<Geometry>);
    const element = document.createElement("div");
    const hasPrenotazione = properties?.properties?.element?.posti?.some((p: any) => p?.stato === EStato.Prenotato);

    const defunti = getDefunti(properties?.properties?.element?.posti);
    const tipoTomba = properties?.properties?.element?.tipo?.descr ?? "";
    const codice = layer === ELayer.Tomba ? properties?.name : properties?.properties?.element?.codice;
    const note = properties?.properties?.element?.note ? `Note: ${properties?.properties?.element?.note}` : "";
    const sfondo = properties?.properties?.element?.colore?.sfondo;
    const color = getContrastingColor(sfondo);
    // element.classList.add("magnify-element");
    element.classList.add("magnify-element");

    element.style.backgroundColor = !hasPrenotazione ? sfondo : "#e5e5e5";
    element.style.color = !hasPrenotazione ? color : "#000000";
    element.style.border = `1px solid ${properties?.properties?.element?.colore?.bordo}`;

    const divNode = document.createElement("p");
    divNode.textContent = `${tipoTomba} ${codice} `;
    element.appendChild(divNode);

    const mapDefunti = defunti?.map((d) => {
        if (d?.tipoDefunto === "R" || !d?.anagrafica) return undefined;
        if (d?.tipoDefunto === "P") return `<li> Prenotato per: ${d?.anagrafica?.nome} ${d?.anagrafica?.cognome}</li>`;
        return `<li>${d?.tipoDefunto}${d?.numero}: ${d?.anagrafica?.cognome} ${d?.anagrafica?.nome}<br/>
        ${d?.anagrafica?.dataNascita ? ` N. ${d?.anagrafica?.dataNascita}` : ""}${
            d?.anagrafica?.dataMorte ? ` M. ${d?.anagrafica?.dataMorte}` : ""
        }</li>`;
    });

    if (mapDefunti) {
        const pNodeCognomeENome = document.createElement("p");
        const pNodeDefunti = document.createElement("ul");

        pNodeCognomeENome.classList.add("magnify-contratti-title");
        pNodeDefunti.innerHTML = mapDefunti.toString().replaceAll(",", " ");
        pNodeDefunti.classList.add("magnify-contratti");
        element.appendChild(pNodeCognomeENome);
        element.appendChild(pNodeDefunti);
    }

    const unicodeElementIcone = document.createElement("p");
    const icone =
        properties?.properties?.element?.icone && properties?.properties?.element?.icone?.length > 0
            ? properties.properties.element.icone
                  .filter((c: any) => c)
                  .map((i: string) => {
                      return i.replace("0x", "&#x");
                  })
            : undefined;

    if (icone) {
        unicodeElementIcone.innerHTML = icone.toString().replaceAll(",", " ");
        unicodeElementIcone.classList.add("fa");
        unicodeElementIcone.style.fontFamily = "Font Awesome 6 Pro";
        unicodeElementIcone.style.fontSize = "1rem";
        divNode.appendChild(unicodeElementIcone);
    }

    const contratti =
        properties?.properties?.element?.contratti && properties?.properties?.element?.contratti?.length > 0
            ? properties.properties.element.contratti.map((c: any) => {
                  if (c?.data && !c?.dataScadenza)
                      return `<li>${c?.anno}/${c?.numero} del ${format(new Date(c?.data), "dd/MM/yyyy")}</li>`;
                  if (c?.data && c?.dataScadenza)
                      return `<li>${c?.anno}/${c?.numero} del ${format(
                          new Date(c?.data),
                          "dd/MM/yyyy"
                      )} scadenza ${format(new Date(c?.dataScadenza), "dd/MM/yyyy")}</li>`;

                  return `<li>${c?.anno}/${c?.numero}</li>`;
              })
            : undefined;

    if (contratti) {
        const pNodeContrattiTitle = document.createElement("p");
        const pNodeContratti = document.createElement("ul");

        pNodeContrattiTitle.textContent = "Contratti:";
        pNodeContrattiTitle.classList.add("magnify-contratti-title");
        pNodeContratti.innerHTML = contratti.toString().replaceAll(",", " ");
        pNodeContratti.classList.add("magnify-contratti");
        element.appendChild(pNodeContrattiTitle);
        element.appendChild(pNodeContratti);
    }

    const pNodeNote = document.createElement("p");
    pNodeNote.textContent = note;
    element.appendChild(pNodeNote);

    return element;
};

const VectorLayer: FC<VectorLayerProps> = ({ source, style }) => {
    const { idCimitero } = useParams();
    const { map } = useMap();

    useEffect(() => {
        if (!map) return undefined;

        const vectorLayer = new OLVectorLayer({
            source,
            style,
        });

        const magnifyOverlay = new Overlay({
            element: document.createElement("div"),
            stopEvent: false,
        });
        map.addOverlay(magnifyOverlay);

        const handlePointerMove = (event: any) => {
            const feature = map.forEachFeatureAtPixel(event.pixel, (f) => f);
            if (feature) {
                const layer = featureLayer(feature as Feature<Geometry>);
                const geometry = feature.getGeometry();
                const extent = geometry?.getExtent();

                if (extent && geometry && layer === ELayer.Tomba && idCimitero) {
                    // const center = getCenter(extent);
                    const view = map.getView();
                    const mapExtent = view.calculateExtent(map.getSize());
                    const topLeft = [mapExtent[0] + 0.4, mapExtent[3] - 1];

                    magnifyOverlay.setPosition(topLeft);
                    magnifyOverlay.setElement(createMagnifyElement(feature, layer));
                }
            } else {
                magnifyOverlay.setPosition(undefined);
            }
        };

        map.on("pointermove", handlePointerMove);

        map.addLayer(vectorLayer);

        return () => {
            if (map) {
                map.removeLayer(vectorLayer);
                map.removeOverlay(magnifyOverlay);
                map.un("pointermove", handlePointerMove);
            }
        };
    }, [map, source, style]);

    return <></>;
};

export default VectorLayer;
