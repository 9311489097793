import React, { FC } from "react";

import { EStato, IPostoTomba } from "@models/Tomba";

import { coloriPosto } from "@helpers/Color";

import { cn } from "@lib/utils";

import PostoTombaPersona from "./PostoTombaPersona";
import PostoTombaStatusBadge from "./PostoTombaStatusBadge";

interface PostoTombaRowProps {
    postoTomba: IPostoTomba;
    compactIfFree?: boolean;
    list?: boolean;
}

const PostoTombaRow: FC<PostoTombaRowProps> = ({ postoTomba, compactIfFree = false, list = false }) => {
    const expanded = compactIfFree && postoTomba.stato === EStato.Libero;
    const getColoriPosto = coloriPosto({ postoTomba });

    return (
        <div
            className={cn("flex space-x-4 items-center", {
                "w-full": !expanded,
            })}
        >
            <PostoTombaStatusBadge
                numero={`${postoTomba.tipo} ${postoTomba.numero}`}
                hasPrenotato={postoTomba.stato === EStato.Prenotato}
                colorePosto={getColoriPosto}
                isSmall
            />
            {!expanded && <PostoTombaPersona postoTomba={postoTomba} list={list} />}
        </div>
    );
};

export default PostoTombaRow;
