import { Suspense, useEffect } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Loader, Message } from "rsuite";

import { clearLocalStorage } from "@services/auth/AccessTokenService";

import { versionBEState, versionState } from "@stores/globalUserSettings";

import { useCruxNavigate } from "@hooks/useCruxNavigate";

import routes from "./index";

const AppRoutes = () => {
    const routeElement = useRoutes(routes);
    const { useQuery } = useCruxNavigate();
    const navigate = useNavigate();

    const setVersion = useSetRecoilState(versionState);
    const storedVersion = useRecoilValue(versionState);
    const CURRENT_VERSION = useRecoilValue(versionBEState);
    const uiPreview = useQuery.get("preview");

    useEffect(() => {
        console.log("storedVersion", {
            storedVersion,
            CURRENT_VERSION,
            isDifferent: storedVersion !== CURRENT_VERSION,
        });

        if (!storedVersion) {
            console.log("imposto la versione");
            setVersion(CURRENT_VERSION);
            return;
        }

        if (storedVersion !== CURRENT_VERSION) {
            clearLocalStorage();
            console.log("clearLocalStorage e reload");
            navigate("login");

            if (CURRENT_VERSION) {
                setVersion(CURRENT_VERSION);
            }

            if (storedVersion) {
                window.location.reload();
            }
        }
    }, [storedVersion, CURRENT_VERSION]);

    return (
        <Suspense fallback={<Loader content='Caricamento in corso...' center size='md' />}>
            {uiPreview && (
                <Message full showIcon type='warning' className='z-10 bottom-0 top-auto'>
                    Attenzione! L'interfaccia grafica &egrave; in modalit&agrave; <b>lettura</b>, potrai solamente
                    consultare la sezione richiesta.
                </Message>
            )}
            {routeElement}
        </Suspense>
    );
};

export default AppRoutes;
