/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from "react";

import { ITombaCardData } from "@modules/cimiteri/pages/Models/tombaCard";

import { cn } from "@lib/utils";

const PostoTombaStatusBadge: FC<Partial<ITombaCardData>> = ({
    numero,
    colorePosto,
    sfondoTomba,
    bordoTomba,
    handleTomba,
    hasPrenotato,
    isStart = false,
    isSmall = false,
    textBg = false,
}) => {
    return (
        <div
            className={cn(
                "rounded-tl-sm rounded-tr-none rounded-bl-sm rounded-br-none w-48  text-white flex justify-center cursor-pointer p-4",
                `${colorePosto ? `bg-${colorePosto}` : ""}`,
                { "pattern-vertical-lines pattern-blue-500 pattern-bg-red-500 pattern-size-1": hasPrenotato },
                { "items-center": !isStart, "items-start py-12": isStart },
                { "min-h-[2rem]": !isSmall, "h-fit": isSmall }
            )}
            style={{ backgroundColor: sfondoTomba, border: `1px solid ${bordoTomba}` }}
            onClick={() => {
                if (handleTomba) handleTomba(false);
            }}
        >
            <div
                className={cn("break-all text-center", {
                    "bg-[#000000bd] rounded p-4": textBg,
                    "text-xs": isSmall,
                })}
            >
                {numero}
            </div>
        </div>
    );
};

export default PostoTombaStatusBadge;
