import { ErrorMessage, useField } from "formik";
import React, { FC } from "react";
import { FlexboxGrid, Form } from "rsuite";

import { cn } from "@lib/utils";

type FormGroupProps = {
    name: string;
    required?: boolean;
    label?: string | React.ReactNode;
    helpText?: any;
    children?: React.ReactNode;
    colspan?: number;
    className?: string;
    transparentLabel?: boolean;
};

const FormGroup: FC<FormGroupProps> = (props) => {
    const { name, helpText, label, children, required, className, colspan = 24, transparentLabel = false } = props;
    const [field, { error }] = useField(name);

    return (
        <Form.Group controlId={name} className={cn({ "has-error": error, required }, "mt-2", className)}>
            {label && (
                <>
                    <Form.ControlLabel className={cn({ required })}>{label} </Form.ControlLabel>
                    <br />
                </>
            )}
            {!label && transparentLabel && <br />}
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={colspan}>{children}</FlexboxGrid.Item>
            </FlexboxGrid>
            {helpText && <Form.HelpText>{helpText}</Form.HelpText>}
            <ErrorMessage name={name}>
                {(errorMessage: string) => <Form.ErrorMessage show>{errorMessage}</Form.ErrorMessage>}
            </ErrorMessage>
        </Form.Group>
    );
};

export default FormGroup;
