import React, { Dispatch, FC, SetStateAction, memo, useCallback, useEffect, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { CheckboxGroup, RadioGroup } from "rsuite";

import { SettoreCimitero } from "@models/SettoreCimitero";
import { Tomba } from "@models/Tomba";

import withLoading from "@hoc/withLoading";

import TombaCard from "./TombaCard";

interface BaseProps {
    searchMode?: boolean;
    historicalData?: boolean;
    multipleOptions?: boolean;
    isTrasferimento?: boolean;
}

interface DataViewProps extends BaseProps {
    tombe: Tomba[];
    settore?: SettoreCimitero | undefined;
    setSelectTomba?: (tomba: Tomba) => void;
    setGetTombaId?: Dispatch<SetStateAction<any[]>>;
}

interface IRowRenderer extends BaseProps {
    data: Tomba[];
    index: number;
    setSize: (index: number, size: number) => void;
    setSelectTomba?: (tomba: Tomba) => void;
    tombaId: any[];
    setTombaId?: Dispatch<SetStateAction<any[]>>;
    handleReload?: () => void;
    defaultExpanded?: { [key: string]: boolean };
    setDefaultExpanded?: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}

const RowRenderer = memo(
    ({
        data,
        index,
        setSize,
        searchMode,
        setSelectTomba,
        historicalData,
        setTombaId,
        tombaId,
        multipleOptions,
        defaultExpanded,
        setDefaultExpanded,
        isTrasferimento,
    }: IRowRenderer) => {
        const rowRef = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (rowRef.current) setSize(index, ((rowRef.current as any)?.getBoundingClientRect()?.height ?? 50) + 12);
        }, [setSize, index]);

        useEffect(() => {
            if (!isTrasferimento)
                setTimeout(() => {
                    setSize(index, ((rowRef.current as any)?.getBoundingClientRect()?.height ?? 50) + 12);
                }, 250);
        }, [defaultExpanded]);

        return (
            <div ref={rowRef}>
                {!multipleOptions ? (
                    <RadioGroup
                        className='space-y-8'
                        name='tomba-select'
                        value={tombaId[0]}
                        onChange={(id: any) => {
                            if (setTombaId) setTombaId([id]);
                        }}
                    >
                        <TombaCard
                            tomba={data[index]}
                            key={data[index].id}
                            searchMode={searchMode}
                            setSelectTomba={setSelectTomba}
                            historicalData={historicalData}
                            list
                            defaultExpanded={defaultExpanded}
                            setDefaultExpanded={setDefaultExpanded}
                        />
                    </RadioGroup>
                ) : (
                    <CheckboxGroup
                        value={tombaId}
                        onChange={(id: any) => {
                            if (setTombaId) setTombaId(id);
                        }}
                    >
                        <TombaCard
                            tomba={data[index]}
                            key={data[index].id}
                            searchMode={searchMode}
                            setSelectTomba={setSelectTomba}
                            historicalData={historicalData}
                            list
                            multipleOptions
                            defaultExpanded={defaultExpanded}
                            setDefaultExpanded={setDefaultExpanded}
                        />
                    </CheckboxGroup>
                )}
            </div>
        );
    }
);

const TombeSettoreViewWrapper: FC<DataViewProps> = ({
    tombe,
    settore,
    searchMode,
    setSelectTomba,
    setGetTombaId,
    historicalData,
    multipleOptions,
    isTrasferimento,
}) => {
    const [defaultExpanded, setDefaultExpanded] = useState<{ [key: string]: boolean }>({});
    const [tombaId, setTombaId] = useState<any[]>([]);
    const listRef = useRef();
    const sizeMap = useRef<{ [key: number]: any }>({});
    const setSize = useCallback((index: any, size: any) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        (listRef.current as any)?.resetAfterIndex(index);
    }, []);

    const getItemSize = (index: number) => sizeMap.current[index] || 50;

    const handleTombaId = (id: any) => {
        setTombaId(id);
        if (setGetTombaId) setGetTombaId(id);
    };

    const hasSettori = settore?.settori && settore?.settori?.length > 0;
    const hasTombe = tombe && tombe.length > 0;

    if (!hasTombe) return null;

    return (
        <AutoSizer className='w-full'>
            {({ height, width }: { height: number; width: number }) => (
                <>
                    <List
                        ref={listRef as any}
                        height={height}
                        itemCount={tombe.length}
                        itemSize={getItemSize}
                        width='100%'
                        itemData={tombe}
                    >
                        {({ data, index, style }) => (
                            <div style={style}>
                                <RowRenderer
                                    data={data}
                                    index={index}
                                    setSize={setSize}
                                    historicalData={historicalData}
                                    setSelectTomba={setSelectTomba}
                                    searchMode={searchMode}
                                    setTombaId={handleTombaId}
                                    tombaId={tombaId}
                                    multipleOptions={multipleOptions}
                                    defaultExpanded={defaultExpanded}
                                    setDefaultExpanded={setDefaultExpanded}
                                    isTrasferimento={isTrasferimento}
                                />
                            </div>
                        )}
                    </List>
                </>
            )}
        </AutoSizer>
    );
};

export default withLoading(TombeSettoreViewWrapper);
