import { RouteObject } from "react-router-dom";
import { lazyLoadRoutes } from "routing/LazyLoadRouter";

const anagraficheSettingsRoutes: RouteObject[] = [
    {
        path: "persone",
        index: true,
        element: lazyLoadRoutes("personeIndex"),
    },
    /* {
        path: "normalizza",
        element: (
            <>
                <div>Normalizza</div>
            </>
        ),
    }, */
    {
        path: "professioni",
        element: lazyLoadRoutes("professioniIndex"),
    },
    {
        path: "gradi-parentela",
        element: lazyLoadRoutes("gradiParentelaIndex"),
    },
    {
        path: "casi-particolari",
        element: lazyLoadRoutes("casiParticolariIndex"),
    },
    {
        path: "imprese",
        element: lazyLoadRoutes("impreseIndex"),
    },
];

export default anagraficheSettingsRoutes;
