import { atom } from "recoil";

import { ColoriConfig } from "@models/Configs";

import { persistRecoil } from "./effects/persistRecoil";

const { persistAtom } = persistRecoil();

const showHistoricalDataState = atom<boolean>({
    key: "showHistoricalDataState",
    default: false,
});

const colorConfigState = atom<ColoriConfig>({
    key: "colorConfigState",
    default: undefined,
});

const versionState = atom<string>({
    key: "versionState",
    default: undefined,
    effects: [persistAtom],
});

const versionBEState = atom<string>({
    key: "versionBEState",
    default: undefined,
    effects: [persistAtom],
});

export { showHistoricalDataState, colorConfigState, versionState, versionBEState };
