import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeftFromLine, faArrowRightFromLine } from "@fortawesome/pro-light-svg-icons";
import {
    faBabyCarriage,
    faCircleExclamation,
    faClock,
    faHouseUser,
    faTombstone,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { Badge, Button } from "rsuite";

import { ETipoDefunto } from "@models/TipoTomba";
import { EStato, IPostoTomba } from "@models/Tomba";

import WithTooltip from "@hoc/withTooltip";

const BadgeWithTooltip = WithTooltip(Button);
const IconWithTooltip = WithTooltip(FontAwesomeIcon);

interface IPostoTombaPersona {
    postoTomba: IPostoTomba;
    activeBadge?: boolean;
    list?: boolean;
}

interface TrasferimentoProps {
    date?: Date;
    tipoTrasferimento?: string;
    tipoDestinazione?: string;
    numero?: string;
    posto?: number;
    posizione?: string;
    tipo?: string;
    source?: boolean;
    entrata?: boolean;
    altroComune?: string;
}

const TrasferimentoRow = ({
    date,
    tipoTrasferimento,
    tipoDestinazione,
    numero,
    posto,
    posizione,
    tipo,
    source = false,
    entrata = false,
    altroComune,
}: TrasferimentoProps) => {
    return (
        <section className='flex flex-wrap items-center text-xs mt-8'>
            <FontAwesomeIcon icon={(entrata ? faArrowRightFromLine : faArrowLeftFromLine) as IconProp} size='lg' />

            {date && <div>&nbsp;{format(new Date(date), "dd/MM/yyyy")}&nbsp;-&nbsp;</div>}
            {tipoTrasferimento && <div>{tipoTrasferimento}&nbsp;</div>}
            {source && posizione && <div>da</div>}
            {!source && posizione && <div>in</div>}
            {posizione && <div className='font-bold'>&nbsp;{posizione}&nbsp;</div>}
            {!altroComune && numero && (
                <div>
                    Num.&nbsp;<span className='font-bold'>{numero}</span>
                </div>
            )}
            {!altroComune && tipo && posto && (
                <div>
                    &nbsp;Posto&nbsp;
                    <span className='font-bold'>
                        {tipo}
                        {posto}
                    </span>
                    &nbsp;
                </div>
            )}
            {!altroComune && tipoDestinazione && <div>({tipoDestinazione})</div>}
            {altroComune && <div>(Altro comune {altroComune})</div>}
        </section>
    );
};

const PostoTombaPersona = ({ postoTomba, activeBadge = false, list = false }: IPostoTombaPersona): JSX.Element => {
    return (
        <section className='flex-col flex w-full h-full'>
            <div className='flex items-center flex-row justify-between space-x-12 w-full h-full text-xs'>
                {(postoTomba?.anagrafica?.cognome || postoTomba?.anagrafica?.nome) && (
                    <div className={`${list ? "flex-row space-x-8 items-center" : "flex-col"} flex w-full h-full`}>
                        <div className='flex items-center justify-start'>
                            <p className='text-black font-semibold'>
                                {postoTomba.stato === EStato.Prenotato ? "Prenotato per " : ""}
                                {postoTomba?.anagrafica?.cognome} {postoTomba?.anagrafica?.nome}
                                {postoTomba.tipoDefunto === ETipoDefunto["Ceneri"] && <span>&nbsp;(Ceneri)</span>}
                                {postoTomba?.anagrafica?.casoParticolare && (
                                    <IconWithTooltip
                                        className='text-red-500 ml-1'
                                        icon={faCircleExclamation}
                                        message={postoTomba?.anagrafica?.casoParticolare.descr}
                                    />
                                )}
                            </p>
                            {activeBadge && (
                                <div className='flex items-center space-x-12 ml-3 flex-1'>
                                    {postoTomba.daVerificare && <Badge color='orange' content='Da verificare' />}
                                    {postoTomba.provvisorio && <Badge color='blue' content='Provvisorio' />}
                                    {!!postoTomba.note && postoTomba.note.length > 0 && (
                                        <BadgeWithTooltip
                                            message={postoTomba.note}
                                            placement='rightStart'
                                            appearance='ghost'
                                            size='xs'
                                            className='cursor-default'
                                        >
                                            <span className='font-bold'>Note</span>
                                        </BadgeWithTooltip>
                                    )}
                                </div>
                            )}
                        </div>
                        {(postoTomba?.anagrafica?.dataNascita ||
                            postoTomba?.anagrafica?.dataMorte ||
                            postoTomba?.dataOccupazione) && (
                            <div className='flex items-center space-x-12 border w-fit px-2 bg-athens-100 border-athens-600'>
                                {postoTomba?.anagrafica?.dataNascita && (
                                    <div className='flex items-center space-x-4'>
                                        <IconWithTooltip
                                            className='text-gray-400'
                                            icon={faBabyCarriage}
                                            message='Data di nascita'
                                        />
                                        <span>{postoTomba?.anagrafica?.dataNascita}</span>
                                    </div>
                                )}
                                {postoTomba?.anagrafica?.dataMorte && (
                                    <div className='flex items-center space-x-4'>
                                        <IconWithTooltip
                                            className='text-gray-400'
                                            icon={faTombstone}
                                            message='Data di morte'
                                        />
                                        <span>{postoTomba?.anagrafica?.dataMorte}</span>
                                    </div>
                                )}
                                {postoTomba?.dataOccupazione && (
                                    <div className='flex items-center space-x-4'>
                                        <IconWithTooltip
                                            className='text-gray-400'
                                            icon={faHouseUser}
                                            message='Data occupazione'
                                        />
                                        <span>{format(new Date(postoTomba.dataOccupazione), "dd/MM/yyyy")}</span>
                                    </div>
                                )}
                                {postoTomba?.oraOccupazione && (
                                    <div className='flex items-center space-x-4'>
                                        <IconWithTooltip
                                            className='text-gray-400'
                                            icon={faClock}
                                            message='Ora occupazione'
                                        />
                                        <span>{postoTomba?.oraOccupazione}</span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {!postoTomba?.anagrafica?.cognome && !postoTomba?.anagrafica?.nome && <p>Posto libero</p>}
            </div>

            {postoTomba?.provenienza && (
                <TrasferimentoRow
                    date={postoTomba?.provenienza?.dataTrasferimento}
                    tipoTrasferimento={postoTomba?.provenienza?.tipoTrasferimento.descr}
                    numero={postoTomba?.provenienza?.tomba?.numero}
                    posto={postoTomba?.provenienza?.posto?.numero}
                    posizione={postoTomba?.provenienza?.tomba?.posizione ?? ""}
                    tipoDestinazione={postoTomba?.provenienza?.tomba?.tipo?.descr}
                    tipo={postoTomba?.provenienza?.posto?.tipo}
                    source
                    entrata
                />
            )}

            {postoTomba.storico && (
                <TrasferimentoRow
                    date={postoTomba?.destinazione?.dataTrasferimento}
                    tipoTrasferimento={postoTomba?.destinazione?.tipoTrasferimento.descr}
                    numero={postoTomba?.destinazione?.tomba?.numero}
                    posto={postoTomba?.destinazione?.posto?.numero}
                    posizione={postoTomba?.destinazione?.tomba?.posizione ?? ""}
                    tipoDestinazione={postoTomba?.destinazione?.tomba?.tipo?.descr}
                    tipo={postoTomba?.destinazione?.posto?.tipo}
                    altroComune={postoTomba?.destinazione?.altroComune ?? ""}
                />
            )}
        </section>
    );
};

export default PostoTombaPersona;
